<template>
  <div>
    <div >
      <img class="img1" src="./新闻资讯/1.png" alt="" />
    </div>
    <div class="zou">
      <div class="han">新闻资讯</div>
      <div class="ying">News information</div>
    </div>
    <!--{{item}}-->
    <div class="container">
      <!--<span><strong>这两个不能一起用吗</strong></span>-->
      <!--<strong> 这两个不能一起用吗</strong>-->
      <div class="zong">
        <!--<img class="img1" :src="item.pic" alt="" />-->
        <div class="one" v-html="delHtmlTag(item.name)">{{ item.name }}</div>

        <!--<div class="two" v-html="item.content"></div>-->
<!--ref="d"-->
          <div  class="two1" v-html="delHtmlTag(item.content)">

        </div>
      </div>
    </div>

    <bottom></bottom>
  </div>
</template>

<script>
import { getNewsList } from "../../api/xinwenzixun";
import Bottom from "../../components/common/Bottom.vue";
export default {
  name: "Juti",
  data() {
    return {
      item: {},
      // ref:"",
    };
  },
  components: {
    // Dao,
    Bottom,
  },
  methods: {

    delHtmlTag(str) {
      // console.log(str)
      // if (str != null || str == undefined  ) {
      //   console.log(str.replace(/<[^>]+>/g, ""))
      //   return str.replace(/<[^>]+>/g, "");
      if (str != null || str == undefined  ) {
        let estr = str
            // .replace(/<[^>]+>/g, "")
            .replace(/&lt;/g,"<")
            .replace(/&gt;/g,">")
            .replace(/&amp;/g, "")
            .replace(/&nbsp;/g,"")
            .replace(/nbsp;/g,"")
            .replace(/&quot;/g, "'")
        ;
        // console.log(estr)
        return estr

      }else {
        // console.log(222);
      }

    },
  },
  mounted() {
    // console.log("我收到新闻资讯的数据啦", JSON.parse(this.$route.query.item));
    // console.log("我收到新闻资讯的数据啦", JSON.parse(this.$route.query.data));
    // this.item = JSON.parse(this.$route.query.item);
    console.log("1111",JSON.parse(window.localStorage.getItem("item")));
    this.item = JSON.parse(window.localStorage.getItem("item"));
  },
  beforeUpdate() {

  }
};
</script>

<style scoped>
/deep/ .img1 {
  max-width: 100%!important;
  width: 100%!important;
  height: 48.6891vh;
  margin-top: 0!important;
  margin-bottom: 0!important;
}
.zou {
  width: 11.1979vw;
  height: 12.9551vh;
  opacity: 0.8;
  background-color: #df333f;
  position: absolute;
  margin-left: 5.5625vw;
  margin-top: -8.4633vh;
}
.han {
  width: 10.7083vw;
  height: 1.4063vw;
  font-size: 1.5625vw;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: left;
  color: #ffffff;
  margin-top: 0.6771vw;
  margin-left: 0.8854vw;
}
.ying {
  width: 10.8125vw;
  height: 1.1979vw;
  font-size: 1.5vw;
  font-family: PingFang SC, PingFang SC-Light;
  font-weight: 300;
  text-align: left;
  color: #ffffff;
  margin-left: 0.8854vw;
  margin-top: 0.6vw;
}
.zong {
  width: 75%;
  margin-left: 12%;
  margin-top: 6vw;
  margin-bottom: 8vw;
}
.one {
  font-size: 1.5vw;
  text-align: center;
  margin-bottom: 2.5vw;
}
/deep/.one span{
  font-size: 1.5vw!important;
  font-family: "Microsoft YaHei";
  font-weight: 400!important;
  color:black !important;
}
.two1 {
  margin: 0px;
  padding: 0px;
  outline: 0px;
  max-width: 100%;
  box-sizing: border-box !important;
  overflow-wrap: break-word !important;
  font-size: 16px!important;
  line-height: 20px!important;
  font-family: 宋体;
}

 /deep/ img{
    padding-left: 0;
    margin-left: 0;
    width:60vw!important;
   /*max-width:  80%!important;*/
margin-bottom: 1vw !important;
    margin-top: 1vw!important;
  }
/deep/span{
  text-indent: 0em!important;
  margin: 0px;
  padding: 0px;
  outline: 0px;
  max-width: 100%;
  box-sizing: border-box !important;
  overflow-wrap: break-word !important;
  font-size: 16px!important;
  line-height: 20px!important;
  font-family: 宋体;
}
/deep/section{
  text-indent: 0em!important;
  margin: 0px;
  padding: 0px;
  outline: 0px;
  max-width: 100%;
  box-sizing: border-box !important;
  overflow-wrap: break-word !important;
  font-size: 16px!important;
  line-height: 20px!important;
  font-family: 宋体;
}
/deep/p{
  text-indent: 0em!important;
  margin: 0px;
  padding: 0px;
  outline: 0px;
  max-width: 100%;
  box-sizing: border-box !important;
  overflow-wrap: break-word !important;
  font-size: 16px!important;
  line-height: 20px!important;
  font-family: 宋体;
}
</style>
